import * as Types from '@x/schemas/ai';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type MessageItemFragment = { __typename: 'MessageObject', role?: string | null | undefined, message: string };

export type MessageDetailFragment = { __typename: 'MessageObject', threadId?: string | null | undefined, message: string, role?: string | null | undefined, assistantId?: string | null | undefined };

export type SendMessageQueryVariables = Types.Exact<{
  message: Types.Scalars['String']['input'];
  threadId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  messageContext?: Types.InputMaybe<Types.MessageContextInput>;
}>;


export type SendMessageQuery = { __typename: 'Query', sendMessage: { __typename: 'MessageObject', threadId?: string | null | undefined, message: string, role?: string | null | undefined, assistantId?: string | null | undefined } };

export type GetMessagesQueryVariables = Types.Exact<{
  threadId: Types.Scalars['String']['input'];
}>;


export type GetMessagesQuery = { __typename: 'Query', getMessages: { __typename: 'GetThreadMessagesObject', messages: Array<{ __typename: 'MessageObject', role?: string | null | undefined, message: string }> } };

export const MessageItemFragmentDoc = gql`
    fragment MessageItem on MessageObject {
  role
  message
}
    `;
export const MessageDetailFragmentDoc = gql`
    fragment MessageDetail on MessageObject {
  threadId
  message
  role
  assistantId
}
    `;
export const SendMessageDocument = gql`
    query SendMessage($message: String!, $threadId: String, $messageContext: MessageContextInput) {
  sendMessage(
    message: $message
    threadId: $threadId
    messageContext: $messageContext
  ) {
    ...MessageDetail
  }
}
    ${MessageDetailFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SendMessageGQL extends Apollo.Query<SendMessageQuery, SendMessageQueryVariables> {
    document = SendMessageDocument;
    client = 'ai';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetMessagesDocument = gql`
    query GetMessages($threadId: String!) {
  getMessages(threadId: $threadId) {
    messages {
      ...MessageItem
    }
  }
}
    ${MessageItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetMessagesGQL extends Apollo.Query<GetMessagesQuery, GetMessagesQueryVariables> {
    document = GetMessagesDocument;
    client = 'ai';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }