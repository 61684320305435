import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { GRAPH_CLIENT } from '@x/common/graph';
import { AiAuthTokenInterceptor } from './auth/auth-token.interceptor';
import { AiChatService } from './services/ai-chat.service';
import { AiAuthTokenContextService } from './auth/ai-auth-token-context.service';

export interface AiClientModuleConfig {
  graphUrl: string;
}

@NgModule({
  imports: [],
})
export class AiClientModule {
  static forRoot(config: AiClientModuleConfig): ModuleWithProviders<AiClientModule> {
    return {
      ngModule: AiClientModule,
      providers: [
        AiChatService,
        AiAuthTokenContextService,
        {
          provide: GRAPH_CLIENT,
          useValue: {
            name: 'ai',
            uri: config.graphUrl,
            defaultOptions: {
              query: { fetchPolicy: 'no-cache', errorPolicy: 'none', returnPartialData: false },
            },
          },
          multi: true,
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: AiAuthTokenInterceptor,
          multi: true,
        },
      ],
    };
  }
}
