import { Injectable } from '@angular/core';
import {
  GetMessagesGQL,
  SendMessageGQL,
  SendMessageQueryVariables,
} from './gql/ai-chat.gql.generated';

@Injectable()
export class AiChatService {
  constructor(
    private sendMessage: SendMessageGQL,
    private getMessages: GetMessagesGQL,
  ) {}

  sendMessageGQL(input: SendMessageQueryVariables) {
    return this.sendMessage.fetch(input);
  }

  getMessagesGQL(threadId: string) {
    return this.getMessages.fetch({ threadId });
  }
}
