import { Injectable } from '@angular/core';
import { IVariableObject, VariableService } from '@x/ecommerce/domain-client';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { distinctUntilChanged, map, tap } from 'rxjs/operators';
import { AuthContextService } from './auth-context.service';

@Injectable()
export class VariableContextService {
  private _globalVariables$ = new BehaviorSubject<Record<string, IVariableObject>>({});
  private _userVariables$ = new BehaviorSubject<Record<string, IVariableObject>>({});

  constructor(
    private readonly authContext: AuthContextService,
    private readonly variableService: VariableService,
  ) {}

  observeUserVariable(key: string): Observable<IVariableObject | null> {
    return this._userVariables$.pipe(
      map((m) => m[key] ?? null),
      distinctUntilChanged(),
    );
  }

  setUserVariable(key: string, value: any) {
    if (!this.authContext.currentUserId) throw new Error('No authenticated user');
    return this.variableService
      .setUserVariable(key, value, this.authContext.currentUserId)
      .pipe(tap(() => {}));
  }

  getUserVariable(key: string) {
    const obs$: Observable<IVariableObject | null> = this.authContext.currentUserId
      ? this.variableService.fetchUserVariable(key, this.authContext.currentUserId)
      : of(null);

    return obs$.pipe(tap((value) => this.patchUserVariable(key, value)));
  }

  setGlobalVariable(key: string, value: any) {
    return this.variableService.setGlobalVariable(key, value);
  }

  getGlobalVariable(key: string) {
    return this.variableService.fetchGlobalVariable(key);
  }

  patchUserVariable(key: string, value: any) {
    this._userVariables$.next({
      ...this._userVariables$.getValue(),
      [key]: value,
    });
  }
}
